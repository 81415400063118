/* eslint-disable no-undef */
/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Accordion, AccordionPanel, Modal } from '@sede-x/shell-ds-react-framework';
import ManualEntryFormContent from '../ManualEntryForm/ManualEntryFormContent';
import InputTableContent from '../InputTable/InputTableContent';
import KPIContent from '../KPIContent/KPIContent';
import './CalculatorForm.css';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/calculatorFormContext';
import { handleSaveDeal } from '../../utils';
import { IAuthToken } from '../../types/types';
import appInsights from '../../appInsights';
import DownloadPdf from './DownloadPdf/DownloadPdf';
import { one, two } from '../../constants';

const liveKpiUrl = process.env.REACT_APP_LIVE_KPI_URL as string;
const isEnvUAT = process.env.REACT_APP_IS_UAT_ENVIRONMENT === 'true';

const CalculatorForm = (props: { userName: string; authUser: IAuthToken }) => {
  const { userName, authUser } = props;
  const { state, dispatch } = useContext(AppContext);

  const [isHideAllButtons, setIsHideAllButtons] = useState(false);
  const [isUAT, setIsUAT] = useState(isEnvUAT);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);

  useEffect(() => {
    const buttons = document.querySelectorAll('button');
    const displayStyle = isHideAllButtons ? 'none' : '';

    buttons.forEach((button) => {
      button.style.display = displayStyle;
    });
  }, [isHideAllButtons]);

  const innerOnClose = () => setIsUAT(false);

  // Calling handle save deal when click on get Output KPI button on step 2
  const {
    activeKey,
    testPlan,
    operatingPlan,
    isGetOutputKpi,
    operatedScopeYearWiseObj,
    equityScopeYearWiseObj,
    carbonIntensityYearWise,
    carbonIntensityUomYearWise,
    carbonEmissionYearWise,
    timeStampDataYearWise,
    scope1CmbCrbnIntYearWiseObj,
  } = state;
  useEffect(() => {
    // Added void to avoid catch block in async function.
    void (async () => {
      !isGetOutputKpi && initialLoad && testPlan === 0 && (await handleSaveDeal(state, userName));
      dispatch({
        type: 'setIsGetOutputKpi',
        payload: false,
      });
    })();
    setInitialLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    operatedScopeYearWiseObj,
    equityScopeYearWiseObj,
    carbonIntensityYearWise,
    carbonIntensityUomYearWise,
    carbonEmissionYearWise,
    timeStampDataYearWise,
    scope1CmbCrbnIntYearWiseObj,
  ]);

  useEffect(() => {
    const isAlreadyLoggedInStorage = 'isAlreadyLoggedInStorage';
    const value: string | null = localStorage.getItem(isAlreadyLoggedInStorage);

    if (!value || value !== 'true') {
      appInsights.trackEvent({
        name: 'User logged in',
        properties: {
          email: authUser?.email,
          username: authUser?.preferred_username,
          role: authUser?.aud,
        },
      });
      localStorage.setItem(isAlreadyLoggedInStorage, 'true');
    }
  }, [authUser]);
  const isTestPlan = testPlan === one;
  const isOperatingPlan = testPlan === 0 && (operatingPlan === one || operatingPlan === 0);
  const isAccordionOpen = isTestPlan || isOperatingPlan;
  useEffect(() => {
    if (!isAccordionOpen || isTestPlan) {
      dispatch({ type: 'setActiveKey', payload: [0] });
    }
  }, [isAccordionOpen, isTestPlan])
  return (
    <>
      <Modal
        title='Welcome to the user acceptance test environment.'
        open={isUAT}
        onClose={() => innerOnClose()}
        actions={[
          {
            label: 'Ok',
            action: () => {
              innerOnClose();
              window.location.href = liveKpiUrl;
            },
          },
          {
            label: 'Cancel',
            action: () => innerOnClose(),
            props: {
              variant: 'outlined',
            },
          },
        ]}
      >
        <div>
          If you are looking for the live Carbon KPI Calculator then please&nbsp;{' '}
          <span className='click-here'>
            <a href={liveKpiUrl}>Click Here</a>
          </span>{' '}
          &nbsp;or type <span>{liveKpiUrl}</span> into your web browser.
          <p>Click OK to redirect or CANCEL to stay on this page.</p>
        </div>
      </Modal>
      <div id='my-node'>
        <div>
          <Accordion activeKey={[...activeKey]}>
            <AccordionPanel
              className='accordion-box-shadow'
              header={
                <div>
                  <span className='acc-label'>
                    <b>STEP 01 &nbsp;&nbsp;</b>
                  </span>
                  <span className='sub-heading'>Deal Entry Form: &nbsp;</span>
                  <span className='sub-text'>Enter the details of your deal.</span>
                </div>
              }
            >
              <ManualEntryFormContent isAccordionOpen={isAccordionOpen} />
            </AccordionPanel>
            <AccordionPanel
              className='accordion-box-shadow'
              header={
                <div>
                  <span className='acc-label'>
                    <b>STEP 02 &nbsp;&nbsp;</b>
                  </span>
                  <span className='sub-heading'>Input Fields Table: &nbsp;</span>{' '}
                  <span className='sub-text'>
                    Enter the values in the table below to show phasing of volumes across life of
                    the deal.
                  </span>
                </div>
              }
            >
              {activeKey.includes(one) && <InputTableContent />}
            </AccordionPanel>
            <AccordionPanel
              className='accordion-box-shadow step3'
              header={
                <div>
                  <span className='acc-label'>
                    <b>STEP 03 &nbsp;&nbsp;</b>
                  </span>
                  <div className='step-text-align-step3'>
                    <span className='sub-heading'>Carbon KPIs: &nbsp;</span>
                    <span className='sub-text'>
                      The KPIs below show the environmental credentials for your deal, which can be
                      copied into or attached to the Deal Approval Form.
                    </span>
                  </div>
                </div>
              }
            >
              {activeKey.includes(two) && <KPIContent />}
              {state.isFormValidated && state.stepTwoTableData.length > 0 && (
                <div className='download-pdf'>
                  <DownloadPdf state={state} setIsHideAllButtons={setIsHideAllButtons} />
                </div>
              )}
            </AccordionPanel>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default CalculatorForm;
