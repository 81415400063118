/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useState } from 'react';
import {
  Flexbox,
  FormField,
  Select,
  Button,
  SingleDatePicker,
  Checkbox,
  Option,
} from '@sede-x/shell-ds-react-framework';
import { IDeals } from '../../../types/types';
import dayjs from 'dayjs';
import { FiltersContext } from '../../../context/FiltersContext';
import {
  disabledNextDatesForStartDate,
  disabledPrevDatesForEndDate,
  handleDateChange,
  isAnyFieldNotEmpty,
} from '../utils';

interface DealsFilterProps {
  allDealsData: IDeals[];
}

const DealsFilter: React.FC<DealsFilterProps> = ({ allDealsData }) => {
  const { filters, dispatch } = useContext(FiltersContext);
  const [isFilterApply, setIsFilterApply] = useState<boolean>(true);

  const opportunityOptions = Object.values(
    allDealsData.reduce((acc: { [key: string]: IDeals }, obj: IDeals) => {
      acc[obj.OPPORTUNITY_NAME] = acc[obj.OPPORTUNITY_NAME] || obj;
      return acc;
    }, {}),
  );

  const commodityOptions = Object.values(
    allDealsData.reduce((acc: { [key: string]: IDeals }, obj: IDeals) => {
      acc[obj.TRADE_COMMODITY_NAME] = acc[obj.TRADE_COMMODITY_NAME] || obj;
      return acc;
    }, {}),
  );

  const countryOptions = Object.values(
    allDealsData.reduce((acc: { [key: string]: IDeals }, obj: IDeals) => {
      acc[obj.COUNTRY_NAME] = acc[obj.COUNTRY_NAME] || obj;
      return acc;
    }, {}),
  );

  useEffect(() => {
    const isFilter = isAnyFieldNotEmpty(filters);
    isFilterApply && setIsFilterApply(isFilter);
  }, [filters, isFilterApply]);

  return (
    <Flexbox flexDirection='column'>
      <div className='filterName'>Filters</div>
      <FormField id='opportunity' label='Opportunity Name'>
        <Select
          mode='multiple'
          size='small'
          value={filters.opportunity}
          allowClear={true}
          aria-label='Select'
          optionLabelProp='label'
          data-testid='select-opportunity'
          onChange={(opportunity) =>
            dispatch({
              type: 'setOpportunity',
              payload: opportunity,
            })
          }
          virtual={false}
        >
          {opportunityOptions?.map((item: IDeals) => (
            <Option key={item.OPPORTUNITY_NAME} value={item.OPPORTUNITY_NAME}>
              {item.OPPORTUNITY_NAME}
            </Option>
          ))}
        </Select>
      </FormField>
      <FormField id='commodity' label='Commodity'>
        <Select
          size='small'
          allowClear={true}
          value={filters.commodity}
          data-testid='select-commodity'
          onChange={(commodity) =>
            dispatch({
              type: 'setCommodity',
              payload: commodity,
            })
          }
        >
          {commodityOptions?.map((item: IDeals) => (
            <Option key={item.TRADE_COMMODITY_NAME} value={item.TRADE_COMMODITY_NAME}>
              {item.TRADE_COMMODITY_NAME}
            </Option>
          ))}
        </Select>
      </FormField>
      <FormField id='country' label='Country'>
        <Select
          size='small'
          allowClear={true}
          value={filters.country}
          data-testid='select-country'
          onChange={(country) =>
            dispatch({
              type: 'setCountry',
              payload: country,
            })
          }
        >
          {countryOptions?.map((item: IDeals) => (
            <Option key={item.COUNTRY_NAME} value={item.COUNTRY_NAME}>
              {item.COUNTRY_NAME}
            </Option>
          ))}
        </Select>
      </FormField>
      <FormField id='startYear' label='Start Year'>
        <SingleDatePicker
          placeholder='DD MMM YYYY'
          value={filters.startDate ? dayjs(filters.startDate) : null}
          elevation={true}
          size='small'
          data-testid='select-start-date'
          disabledDate={(current) => disabledNextDatesForStartDate(current, filters)}
          onChange={(e) => handleDateChange(e, dispatch, 'setStartDate')}
        />
      </FormField>
      <FormField id='endYear' label='End Year'>
        <SingleDatePicker
          value={filters.endDate ? dayjs(filters.endDate) : null}
          placeholder='DD MMM YYYY'
          elevation={true}
          size='small'
          data-testid='select-end-date'
          disabledDate={(current) => disabledPrevDatesForEndDate(current, filters)}
          onChange={(e) => handleDateChange(e, dispatch, 'setEndDate')}
        />
      </FormField>
      <FormField>
        <Checkbox
          label='Equity Scope 1 & 2'
          checked={filters.isEquityScope}
          onChange={(event) => {
            setIsFilterApply(false);
            dispatch({
              type: 'setIsEquityScope',
              payload: event.target.checked,
            });
          }}
        />{' '}
      </FormField>
      <Button
        className='getDataBtn'
        size='small'
        onClick={() => {
          dispatch({
            type: 'setIsApplyFilterClicked',
            payload: true,
          });
        }}
        disabled={isFilterApply || filters.isClearAll}
      >
        Apply Filters
      </Button>
      <Button
        className='getDataBtn'
        variant='outlined'
        style={{ marginTop: '10px' }}
        data-testid='clear-filter'
        size='small'
        onClick={() => {
          dispatch({
            type: 'setClearAll',
            payload: true,
          });
        }}
      >
        Clear Filters
      </Button>
    </Flexbox>
  );
};
export default DealsFilter;
