export const pointzerofive = 0.05;
export const pointzerofour = 0.04;
export const PointTwoNineThree = 0.293;
export const point1 = 0.1;
export const pointtwo = 0.2;
export const pointthree = 0.3;
export const pointhreefour = 0.34;
export const pointthreenine = 0.39;
export const pointfour = 0.4;
export const pointfourfive = 0.45;
export const pointfive = 0.5;
export const pointfiveeight = 0.58;
export const pointsix = 0.6;
export const pointeight = 0.8;
export const pointnine = 0.9;
export const one = 1;
export const two = 2;
export const three = 3;
export const ThreePointSix = 3.6;
export const threepointfourone = 3.41;
export const four = 4;
export const five = 5;
export const six = 6;
export const seven = 7;
export const ten = 10;
export const twelve = 12;
export const twenty = 20;
export const thirty = 30;
export const thirtynine = 39;
export const forty = 40;
export const fortyone = 41;
export const fiftynine = 59;
export const fiftyeight = 58;
export const eighty = 80;
export const hundred = 100;
export const onefifty = 150;
export const onethirtynine = 139;
export const twohundred = 200;
export const fivehundres = 500;
export const twotwenty = 220;
export const twofifty = 250;
export const thousand = 1000;
export const thousandfivehundred = 1500;
export const fivethousand = 5000;
export const tenlakh = 1000000;
export const year2012 = 2012;
export const year2016 = 2016;
export const eigthteenPointFour = 18.4;
export const fiveHundreds = 500;
export const loginPageTitle =
  "Authenticate your account by logging into Shell's single sign-on provider.";
export const LOGOUT = 'logout';
export const TITLE_KPI_CALCULATOR = 'Carbon KPI Calculator';
export const SUB_TITLE_KPI_CALCULATOR = 'Shell Energy';
export const TABS_MENU = [
  {
    key: '0',
    path: '/kpi-calculator',
    label: 'Deal Entry Form',
  },
  {
    key: '1',
    path: '/all-deals',
    label: 'All Deals',
  },
];

export const ALL_CARBON_DEALS_HEADING = 'All Deals';
export const ALL_CARBON_DEALS_SUMMARY =
  'Note: Carbon Emissions and Equity Scope 1 & 2 are shown in MTPA, and Margin to Carbon Ratio (MCR) is shown in $/tCO2e.';

export const fieldKeys = [
  'Operated Scope 1&2 (MTPA)',
  'Equity Scope 1&2 (MTPA)',
  'Net Absolute Emissions (MTPA)',
  'Avg Carbon Intensity**',
];
export const dataKeys = ['OPERATED_SCOPE', 'EQUITY_SCOPE', 'CARBON_EMISSIONS', 'CARBON_EMISSIONS'];
export const DEAL_MODAL_TITLE = 'Confirm Delete';
export const DEAL_MODAL_SUBTITLE = 'Are you sure you want to delete the selected deals?';
export const DEAL_MODAL_WARNING =
  '** The deal will be archived in the system. It can be recovered later.';
export const DEAL_TYPE_LABEL = ["If you are using the KPI Calculator to test a deal, click Yes.",
  "If you are using the KPI Calculator to enter in the details of a 'live' deal being actively worked, click No."];
export const OPERATING_PLAN_LABEL = 'Is the deal included in OP?';
export const DEAL_SUPPORT_CONTACT = 'Please contact the Shell Energy Carbon Portfolio Team.';
export const TYPE_OF_DEAL_OPTIONS = [{
  value: one,
  label: 'Yes',
}, {
  value: 0,
  label: 'No',
}];

export const OPERATING_PLAN_OPTIONS = [{
  value: one,
  label: 'Yes',
}, {
  value: 0,
  label: 'No',
},
{
  value: two,
  label: 'Not Sure',
}];
