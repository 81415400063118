import Decimal from 'decimal.js';
import { Key } from 'react';
import {
  CarbonIntensityForGridInterface,
  CarbonIntensityForPipelineGasAsCommodity,
  CarbonIntensityForNonGridInterface,
  GasFiredPowerTableDataInterface,
  ITableData,
  YearWiseData,
  type ICalculatorFormState,
  IOptions,
} from '../../types/types';
import { calculateFormInitialState } from '../InitialStates/CalculatorInitialState';
import { createReducer } from '../ReducerFunction';

const actionHandlers = {
  setTechnologySource: (state: ICalculatorFormState, payload: string[]) => ({
    ...state,
    technologySource: payload,
  }),
  setSelectedTechnologySources: (
    state: ICalculatorFormState,
    payload: Array<{ value: string; id: number }>,
  ) => ({
    ...state,
    selectedTechnologySources: payload,
  }),
  setStartYear: (state: ICalculatorFormState, payload: number) => ({
    ...state,
    startYear: payload,
  }),
  setEndYear: (state: ICalculatorFormState, payload: number) => ({ ...state, endYear: payload }),
  setStartDate: (state: ICalculatorFormState, payload: string) => ({
    ...state,
    startDate: payload,
  }),
  setEndDate: (state: ICalculatorFormState, payload: string) => ({ ...state, endDate: payload }),
  setCommodity: (state: ICalculatorFormState, payload: string) => ({
    ...state,
    commodity: payload,
  }),
  setCommodityId: (state: ICalculatorFormState, payload: number) => ({
    ...state,
    commodityId: payload,
  }),
  setCountryId: (state: ICalculatorFormState, payload: number) => ({
    ...state,
    countryId: payload,
  }),
  setGridLocation: (state: ICalculatorFormState, payload: string) => ({
    ...state,
    gridLocation: payload,
  }),
  setGridLocationId: (state: ICalculatorFormState, payload: number) => ({
    ...state,
    gridLocationId: payload,
  }),
  setGrossMargin: (state: ICalculatorFormState, payload: Decimal) => ({
    ...state,
    grossMargin: payload,
  }),
  setRegion: (state: ICalculatorFormState, payload: string) => ({ ...state, region: payload }),
  setOpportunity: (state: ICalculatorFormState, payload: string) => ({
    ...state,
    opportunity: payload,
  }),
  setBusiness: (state: ICalculatorFormState, payload: string) => ({ ...state, business: payload }),
  setBusinessId: (state: ICalculatorFormState, payload: number) => ({
    ...state,
    businessId: payload,
  }),
  setOperatingModel: (state: ICalculatorFormState, payload: string) => ({
    ...state,
    operatingModel: payload,
  }),
  setEfficiency: (state: ICalculatorFormState, payload: string) => ({
    ...state,
    efficiency: payload,
  }),
  setEquityShare: (state: ICalculatorFormState, payload: string) => ({
    ...state,
    equityShare: payload,
  }),
  setFuelType: (state: ICalculatorFormState, payload: string) => ({ ...state, fuelType: payload }),
  setAssetType: (state: ICalculatorFormState, payload: string) => ({
    ...state,
    assetType: payload,
  }),
  setUomVal: (state: ICalculatorFormState, payload: string) => ({ ...state, uomVal: payload }),
  setIsGoToStepTwoClicked: (state: ICalculatorFormState, payload: boolean) => ({
    ...state,
    isGoToStepTwoClicked: payload,
  }),
  setIsFormValidated: (state: ICalculatorFormState, payload: boolean) => ({
    ...state,
    isFormValidated: payload,
  }),
  setStepTwoTableFirstCol: (state: ICalculatorFormState, payload: string) => ({
    ...state,
    stepTwoTableFirstCol: payload,
  }),
  setCarbonIntensityForGrid: (
    state: ICalculatorFormState,
    payload: CarbonIntensityForGridInterface[],
  ) => ({
    ...state,
    carbonIntensityForGrid: payload,
  }),
  setCarbonIntensityForNonGrid: (
    state: ICalculatorFormState,
    payload: CarbonIntensityForNonGridInterface[],
  ) => ({
    ...state,
    carbonIntensityForNonGrid: payload,
  }),
  setUserTableInputData: (state: ICalculatorFormState, payload: Record<number, string>) => ({
    ...state,
    userTableInputData: payload,
  }),
  setIsShowKpiData: (state: ICalculatorFormState, payload: boolean) => ({
    ...state,
    isShowKpiData: payload,
  }),
  setGasFiredPowerTableData: (
    state: ICalculatorFormState,
    payload: GasFiredPowerTableDataInterface[],
  ) => ({
    ...state,
    gasFiredPowerTableData: payload,
  }),
  setCarbonIntensityForGas: (
    state: ICalculatorFormState,
    payload: CarbonIntensityForPipelineGasAsCommodity[],
  ) => ({
    ...state,
    carbonIntensityForGas: payload,
  }),
  setStepTwoTableData: (state: ICalculatorFormState, payload: ITableData[]) => ({
    ...state,
    stepTwoTableData: payload,
  }),
  setIsGetOutputKpi: (state: ICalculatorFormState, payload: boolean) => ({
    ...state,
    isGetOutputKpi: payload,
  }),
  setActiveKey: (state: ICalculatorFormState, payload: Key[]) => ({ ...state, activeKey: payload }),
  setIsGetOutputKpiServiceFailure: (state: ICalculatorFormState, payload: boolean) => ({
    ...state,
    isGetOutputKpiServiceFailure: payload,
  }),
  setIsHideAllButtons: (state: ICalculatorFormState, payload: boolean) => ({
    ...state,
    isHideAllButtons: payload,
  }),
  setCarbonIntegrationData: (state: ICalculatorFormState, payload: object[]) => ({
    ...state,
    carbonIntegrationData: payload,
  }),
  setIsFetchCIdataServiceFailure: (state: ICalculatorFormState, payload: boolean) => ({
    ...state,
    isFetchCIdataServiceFailure: payload,
  }),
  setOperatedScope: (state: ICalculatorFormState, payload: number) => ({
    ...state,
    operatedScope: payload,
  }),
  setEquityScope: (state: ICalculatorFormState, payload: number) => ({
    ...state,
    equityScope: payload,
  }),
  setOperatedScopeYearWiseObj: (state: ICalculatorFormState, payload: YearWiseData) => ({
    ...state,
    operatedScopeYearWiseObj: payload,
  }),
  setEquityScopeYearWiseObj: (state: ICalculatorFormState, payload: YearWiseData) => ({
    ...state,
    equityScopeYearWiseObj: payload,
  }),
  setCarbonIntensityForGasFiredPowerYearWise: (
    state: ICalculatorFormState,
    payload: Record<number, number>,
  ) => ({
    ...state,
    carbonIntensityForGasFiredPowerYearWise: payload,
  }),
  setTimeStampDataForGasFiredPowerYearWise: (
    state: ICalculatorFormState,
    payload: Record<number, string>,
  ) => ({
    ...state,
    timeStampDataForGasFiredPowerYearWise: payload,
  }),
  setCarbonIntensityYearWise: (state: ICalculatorFormState, payload: YearWiseData[]) => ({
    ...state,
    carbonIntensityYearWise: payload,
  }),
  setCarbonIntensityUomYearWise: (state: ICalculatorFormState, payload: YearWiseData[]) => ({
    ...state,
    carbonIntensityUomYearWise: payload,
  }),
  setCarbonEmissionYearWise: (state: ICalculatorFormState, payload: YearWiseData[]) => ({
    ...state,
    carbonEmissionYearWise: payload,
  }),
  setTimeStampDataYearWise: (state: ICalculatorFormState, payload: YearWiseData[]) => ({
    ...state,
    timeStampDataYearWise: payload,
  }),
  setScope1CmbCrbnInt: (state: ICalculatorFormState, payload: number) => ({
    ...state,
    scope1CmbCrbnInt: payload,
  }),
  setScope1CmbCrbnIntYearWiseObj: (state: ICalculatorFormState, payload: YearWiseData) => ({
    ...state,
    scope1CmbCrbnIntYearWiseObj: payload,
  }),
  setPowerPerformanceStandardKpiMessage: (state: ICalculatorFormState, payload: string) => ({
    ...state,
    powerPerformanceStandardKpiMessage: payload,
  }),
  setMcrAverage: (state: ICalculatorFormState, payload: number) => ({
    ...state,
    mcrAverage: payload,
  }),
  setIsSuperUser: (state: ICalculatorFormState, payload: boolean) => ({
    ...state,
    isSuperUser: payload,
  }),
  setTestPlan: (state: ICalculatorFormState, payload: number) => ({
    ...state,
    testPlan: payload,
  }),
  setOperatingPlan: (state: ICalculatorFormState, payload: number) => ({
    ...state,
    operatingPlan: payload,
  }),
  setCountryOptions: (state: ICalculatorFormState, payload: IOptions[]) => ({
      ...state,
      countryOptions: payload,
    }),
};
export const calculatorFormReducer = createReducer(calculateFormInitialState, actionHandlers);
