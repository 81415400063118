import React, { useContext, useEffect, useState } from 'react';
import { Flexbox, Grid, RadioButton } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../context/calculatorFormContext';
import { DEAL_SUPPORT_CONTACT, DEAL_TYPE_LABEL, one, OPERATING_PLAN_LABEL, OPERATING_PLAN_OPTIONS, two, TYPE_OF_DEAL_OPTIONS } from '../../constants';
import { NotSureDisclaimer, UserActionText } from './UserAction.styles';

const UserAction = () => {
    const [selectedDealOption, setSelectedDealOption] = useState(-one);
    const [selectedOpOption, setSelectedOpOption] = useState(-one);
    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {
        if (selectedDealOption !== two) {
            dispatch({
                type: 'setOperatingPlan',
                payload: selectedOpOption,
            });
        }
    }, [selectedOpOption]);

    useEffect(() => {
        dispatch({
            type: 'setTestPlan',
            payload: selectedDealOption,
        });

        setSelectedOpOption(-one);
    }, [selectedDealOption])

    return (
        <Grid columns={'60% 40%'}>
            <Grid.Cell>
                {DEAL_TYPE_LABEL.map((label) => (
                    <UserActionText key={label}>{label}</UserActionText>
                ))}
                <Flexbox gap='10px'>
                    {TYPE_OF_DEAL_OPTIONS.map((option) => (
                        <RadioButton
                            key={option.value}
                            label={option.label}
                            value={option.value}
                            size='small'
                            checked={selectedDealOption === option.value}
                            onChange={() => setSelectedDealOption(option.value)}
                        />
                    ))}
                </Flexbox>
            </Grid.Cell>
            {selectedDealOption === 0 &&
                <Grid.Cell>
                    <UserActionText>{OPERATING_PLAN_LABEL}</UserActionText>
                    <Flexbox gap='10px'>
                        {OPERATING_PLAN_OPTIONS.map((option) => (
                            <RadioButton
                                key={option.value}
                                label={option.label}
                                value={option.value}
                                size='small'
                                checked={selectedOpOption === option.value}
                                onChange={() => setSelectedOpOption(option.value)}
                            />
                        ))}
                    </Flexbox>
                    {state.operatingPlan === two && <NotSureDisclaimer>{DEAL_SUPPORT_CONTACT}</NotSureDisclaimer>}
                </Grid.Cell>
            }
        </Grid>
    );
}

export default UserAction;