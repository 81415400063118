import { GlobalHeader } from '@sede-x/glass-design-library';
import React from 'react';
import './MainHeader.css';
import { Link, useLocation } from 'react-router-dom';
import { Menu, MenuItem } from '@sede-x/shell-ds-react-framework';
import { LOGOUT, SUB_TITLE_KPI_CALCULATOR, TABS_MENU, TITLE_KPI_CALCULATOR } from '../../constants';
import { FEEDBACK_LABEL, FEEDBACK_LINK, HELP_LABEL, HELP_LINK } from '../../api/constants';
import CustomButton from '../CustomButton/CustomButton';
import TabMenu from './TabMenu';
import { MainHeaderContainer } from './MainHeader.styles';

interface MainHeaderProps {
  shortname: string;
  emailAddress: string;
  logout: () => void;
  isSuperUser: boolean;
}

const MainHeader: React.FC<MainHeaderProps> = ({
  shortname,
  emailAddress,
  logout,
  isSuperUser,
}) => {
  const location = useLocation()
  const itemRender = ({ path, children }: { path: string; children: React.ReactNode }) => (
    <Link to={path}>{children}</Link>
  );

  const tabsMenu = isSuperUser
    ? TABS_MENU
    : TABS_MENU.filter((tabMenu) => tabMenu.key !== 'all-deals'); // Removing All deals tab if user doesn't have access
  const activeKey = tabsMenu.find((tabMenu) => tabMenu.path === location.pathname)?.key ?? '0';
  return (
    <MainHeaderContainer>
      <GlobalHeader
        hideGlassHub
        avatar={{
          description: emailAddress,
          title: shortname,
          dropdownOverlay: (
            <Menu>
              <MenuItem key='logout' onClick={logout}>
                {LOGOUT}
              </MenuItem>
            </Menu>
          ),
        }}
        subtitle={SUB_TITLE_KPI_CALCULATOR}
        title={TITLE_KPI_CALCULATOR}
        centerComponent={
          <TabMenu items={tabsMenu} itemRender={itemRender} activeKey={activeKey} />
        }
        rightComponent={
          <span className='display-flex'>
            <CustomButton label={HELP_LABEL} link={HELP_LINK} />
            <CustomButton label={FEEDBACK_LABEL} link={FEEDBACK_LINK} />
          </span>
        }
      />
    </MainHeaderContainer>
  );
};

export default MainHeader;
