import { Card, Alert, Sizes, Spacings } from '@sede-x/shell-ds-react-framework';
import {
  POWER_PERFORMANCE_STANDARD,
  POWER_PERFORMANCE_STANDARD_DESCRIPTION,
} from '../../api/constants';
import { attachEmailLinkToText, containsHTMLTags } from '../../utils';
import { useContext } from 'react';
import { AppContext } from '../../context/calculatorFormContext';
import { three } from '../../constants';

export const KpiMessageView = (props: { kpiMessage: string[]; isGasFiredPower: boolean }) => {
  const { state } = useContext(AppContext);
  return (
    <Card
      size={Sizes.Small}
      bodyPadding={true}
      padding={Spacings.Tight}
      className={
        props.isGasFiredPower &&
        Number(state.operatingModel) !== three &&
        props.kpiMessage.length !== 0
          ? ''
          : 'hideKpiMessage'
      }
      header={{
        title: POWER_PERFORMANCE_STANDARD,
        description: props.isGasFiredPower ? POWER_PERFORMANCE_STANDARD_DESCRIPTION : '',
      }}
    >
      <Alert iconVisibility={false}>
        {props.kpiMessage.map(
          (message, _index) =>
            message !== '' && (
              <ul style={{ padding: 0, margin: 0 }} key={`${message}`}>
                {containsHTMLTags(message) ? (
                  <li dangerouslySetInnerHTML={{ __html: attachEmailLinkToText(message) }} />
                ) : (
                  <li>{attachEmailLinkToText(message)}</li>
                )}
              </ul>
            ),
        )}
      </Alert>
    </Card>
  );
};
