import { Card, DeprecatedTable, Sizes, Spacings } from '@sede-x/shell-ds-react-framework';
import { KPI_SUMMARY } from '../../api/constants';
import { IKpiCardData, type IKpiCardDataColumnObject } from '../../types/types';
import { eighty, twofifty } from '../../constants';

export const KpiCardView = (props: { cardTableData: IKpiCardData[] }) => {
  const cardCols: string[] = ['KPIs', 'Value', 'Unit'];
  const cardTableCols = () => {
    const cardColObj: IKpiCardDataColumnObject[] = [];
    cardCols.forEach((val: string | number) => {
      cardColObj.push({
        dataIndex: val,
        width: val === 'KPIs' ? twofifty : eighty,
        key: val,
        title: val,
        className: 'leftAlign',
      });
    });
    return cardColObj;
  };
  return (
    <Card
      size={Sizes.Small}
      bodyPadding={true}
      header={{
        title: KPI_SUMMARY,
      }}
      className='kpiCardMaxWidth'
      padding={Spacings.Tight}
    >
      <DeprecatedTable
        size='small'
        data={props.cardTableData}
        columns={cardTableCols()}
        tableLayout='fixed'
        rowKey={'KPIs'}
        wrapperStyle={{
          textAlign: 'left',
        }}
      />
    </Card>
  );
};
