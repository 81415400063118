import styled from "styled-components";

export const UserActionText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    padding-bottom: 5px;
`
export const NotSureDisclaimer = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #eab219;
    padding-top: 10px;
`