/* eslint-disable no-unused-vars */
import React from 'react';
import { Menu } from '@sede-x/shell-ds-react-framework';
import { IMenuItem } from '../../types/types';

interface TabMenuProps {
  items: IMenuItem[];
  itemRender: (item: { path: string; children: React.ReactNode }) => React.ReactElement;
  activeKey: string;
}

const TabMenu: React.FC<TabMenuProps> = ({ items, itemRender, activeKey }) => (
  <Menu
    data-testid='menu_items'
    className='tabMenu'
    mode={'horizontal'}
    items={items}
    itemRender={itemRender}
    selectedKeys={[activeKey]}
  />
);

export default TabMenu;
