import { useState, useEffect, useContext, useReducer } from 'react';
import '../CalculatorForm/CalculatorForm.css';
import CustomLabel from './customLabel';
import Loader from '../Loader';
import { AppContext } from '../../context/calculatorFormContext';
import { manualFormInitialState } from '../../reducers/InitialStates/ManualFormInitialState';
import { manualFormReducer } from '../../reducers/ActionHandlers/ManualFormActionHandler';
import { WarningMessage } from './WarningMessage';
import { KpiForm } from './KpiForm/KpiForm';
import { ManualFormButtons } from './ManualFormButtons';
import { KpiFormMessage } from './KpiFormMessage';
import { fetchDropDownData, setUom } from './KpiForm/KpiFormFunctions';
import UserAction from './UserAction';

const ManualEntryFormContent = (props: { isAccordionOpen: boolean }) => {
  const { state, dispatch } = useContext(AppContext);
  const [myState, myDispatch] = useReducer(manualFormReducer, manualFormInitialState);
  const [business, setBusiness] = useState<string>('');
  const [commodity, setCommodity] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const { isAccordionOpen } = props;

  useEffect(() => {
    void fetchDropDownData(myDispatch);
  }, []);

  // on page load,set the uom value as MWh else set as per condition
  useEffect(() => {
    setUom(business, commodity, myDispatch, dispatch);
  }, [business, commodity]);
  return (
    <>
      <UserAction />
      {isAccordionOpen &&
        <>
          <WarningMessage showMessage={myState.isWarningMsgAvailable} myDispatch={myDispatch} />
          <Loader isLoading={myState.isLoading} message={'Fetching Form Options'} />
          <KpiForm
            setBusiness={setBusiness}
            setCommodity={setCommodity}
            setCountry={setCountry}
            myState={myState}
            myDispatch={myDispatch}
            business={business}
            country={country}
            isAccordionOpen={isAccordionOpen}
          />
          <KpiFormMessage />
          <div className='align-btns-right'>
            <div>
              <CustomLabel />
            </div>
            <ManualFormButtons country={country} myState={myState} myDispatch={myDispatch} />
          </div>
        </>
      }
    </>
  );
};

export default ManualEntryFormContent;
